// import { device } from 'constants/device';

import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Error404Wrapper = styled.div`
  width: 100%;
  margin: auto;
  max-width: 420px;
  padding: 32px 16px 80px 16px;

  ${up('md')} {
    max-width: 550px;
    padding: 60px 0 100px 0;
  }
`;
export const ImgWrapper = styled.div`
  position: relative;
  min-height: 165px;

  ${up('md')} {
    min-height: 250px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 452px;
  margin: 40px auto auto;
  width: 100%;
  text-align: center;

  h1 {
    color: ${({ theme }) => theme.brand.blue};

    font-size: 2.4rem;

    ${up('md')} {
      font-size: 4.8rem;
      line-height: 5.8rem;
    }
  }
  span {
    font-size: 1.8rem;
    line-height: 1.4;
    font-weight: 500;
  }
  button {
    margin: 16px auto 0 auto;
  }
`;
