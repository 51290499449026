import { FC, memo } from 'react';
import Link from 'next/link';
import Image from 'next/image';

import { Button, Headings } from '@studenten/ui-components';
import Layout from 'features/layout/templates/Layout';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';
import { size } from 'constants/device';

import { Error404Wrapper, ImgWrapper, TextWrapper } from './Error404.styles';

const Error404: FC = () => {
  const { width } = useWindowSize();
  const isMobile = width < size.tablet;
  return (
    <Layout>
      <Error404Wrapper>
        <ImgWrapper>
          <Image priority layout="fill" src={'/assets/404.svg'} alt="404 - page not found." />
        </ImgWrapper>
        <TextWrapper>
          <Headings.H1>Whoops, that page is not existing</Headings.H1>
          <span>
            We can’t seem to find the page you’re looking for. But you can go to the homepage and
            find another.
          </span>
          <Link href="/">
            <Button
              variant="primary"
              width={isMobile ? '240px' : '180px'}
              height={isMobile ? '38px' : '42px'}
            >
              Back to Homepage
            </Button>
          </Link>
        </TextWrapper>
      </Error404Wrapper>
    </Layout>
  );
};

export default memo(Error404);
